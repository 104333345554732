<template>
  <div>
    <h1>{{code.title}}</h1>
    <p v-if="error">{{error}}</p>
    <VueMarkdown :source="code.text" v-if="code.text" />
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'Code',
  components: {
    VueMarkdown,
  },
  data() {
    return {
      code: {},
      error: null,
    };
  },
  async mounted() {
    const token = await localStorage.getItem('token')
    this.axios
      .get('authcode/get', {
        params: {
          code: this.$route.params.code,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        this.code = r.data;
      })
      .catch((r) => {
        if (r.response.data === 'invalid_code') this.error = 'code invalid'
        if (r.response.data === 'ip_mismatch') this.error = 'you\'re not allowed to view this'
      })
  },
  methods: {
  },
}
</script>

<style scoped>

p {
  font-size: 16pt !important;
}

</style>